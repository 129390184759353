import React from 'react';

const Clientes = React.lazy(() => import('../pages/ResponsavelLeadClientes'));

const routes = [
  {
    path: '/responsavelLead/clientes',
    Component: Clientes,
  },
];

export default routes;
