import React, {
  useState,
  useEffect,
  useRef,
  createContext,
  useContext,
  useMemo,
} from 'react';

import Notification from '../components/Notification';
import {
  ID_KEY_ADMIN,
  TOKEN_KEY_ADMIN,
  USER_NAME_ADMIN,
  ID_KEY_RESPONSAVELLEAD,
  TOKEN_KEY_RESPONSAVELLEAD,
  USER_NAME_RESPONSAVELLEAD,
} from '../constants/auth';
import api from '../services/api';

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [loggedUser, setLoggedUser] = useState({});
  const [userTokenAdmin, setUserTokenAdmin] = useState(null);
  const [userTokenResponsavelLead, setUserTokenResponsavelLead] = useState(null); //
  const [isAuthenticatedAdmin, setIsAuthenticatedAdmin] = useState(false);
  const [isAuthenticatedResponsavelLead, setIsAuthenticatedResponsavelLead] = useState(false);
  const notificationRef = useRef();

  useEffect(() => {
    console.log(userTokenAdmin);
    if (!userTokenAdmin) {
      setIsAuthenticatedAdmin(false);
      return;
    }

    (async () => {
      try {
        const { data } = await api.get('/getAdmin');
        console.log(data);
        if (data) {
          setIsAuthenticatedAdmin(true);
        }
        setLoggedUser(data);
      } catch (error) {
        console.log(error);
        setIsAuthenticatedAdmin(false);
      }
    })();
  }, [userTokenAdmin]);

  useEffect(() => {
    console.log(userTokenResponsavelLead);
    if (!userTokenResponsavelLead) {
      setIsAuthenticatedResponsavelLead(false);
      return;
    }

    (async () => {
      try {
        const { data } = await api.get('/getResponsavelLead');
        console.log(data);
        if (data) {
          setIsAuthenticatedResponsavelLead(true);
        }
        setLoggedUser(data);
      } catch (error) {
        console.log(error);
        setIsAuthenticatedResponsavelLead(false);
      }
    })();
  }, [userTokenResponsavelLead]);

  const value = useMemo(
    () => ({
      isAuthenticatedAdmin,
      isAuthenticatedResponsavelLead,
      loggedUser,
      signInAdmin: (token, user) => {
        localStorage.setItem(TOKEN_KEY_ADMIN, token);
        localStorage.setItem(ID_KEY_ADMIN, user.id || user._id);
        localStorage.setItem(USER_NAME_ADMIN, user.name);
        setUserTokenAdmin(token);
      },
      signOutAdmin: () => {
        console.log('sign out');
        api.delete(`/tokens/${userTokenAdmin}`);
        localStorage.removeItem(TOKEN_KEY_ADMIN);
        localStorage.removeItem(ID_KEY_ADMIN);
        localStorage.removeItem(USER_NAME_ADMIN);
        setUserTokenAdmin(null);
      },
      signInResponsavelLead: (token, user) => {
        localStorage.setItem(TOKEN_KEY_RESPONSAVELLEAD, token);
        localStorage.setItem(ID_KEY_RESPONSAVELLEAD, user.id || user._id);
        localStorage.setItem(USER_NAME_RESPONSAVELLEAD, user.name);
        setUserTokenResponsavelLead(token);
      },
      signOutResponsavelLead: () => {
        console.log('sign out');
        api.delete(`/tokens/${userTokenResponsavelLead}`);
        localStorage.removeItem(TOKEN_KEY_RESPONSAVELLEAD);
        localStorage.removeItem(ID_KEY_RESPONSAVELLEAD);
        localStorage.removeItem(USER_NAME_RESPONSAVELLEAD);
        setUserTokenResponsavelLead(null);
      },
    }),
    [
      isAuthenticatedAdmin,
      isAuthenticatedResponsavelLead,
      loggedUser,
      userTokenAdmin,
      userTokenResponsavelLead,
    ],
  );

  useEffect(() => {
    const tokenAdmin = localStorage.getItem(TOKEN_KEY_ADMIN);
    if (tokenAdmin) {
      setUserTokenAdmin(tokenAdmin);
    }
  }, []);

  useEffect(() => {
    const tokenResponsavelLead = localStorage.getItem(TOKEN_KEY_RESPONSAVELLEAD);
    if (tokenResponsavelLead) {
      setUserTokenResponsavelLead(tokenResponsavelLead);
    }
  }, []);

  return (
    <AuthContext.Provider value={value}>
      <Notification ref={notificationRef} />
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within a AuthProvider');
  }

  return context;
};

export default AuthProvider;
