/* eslint-disable no-nested-ternary */
import React, { Suspense } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { Spinner } from 'reactstrap';

import { useAuth } from './app/hooks/auth';
import { ManagerProvider } from './app/hooks/manager';
import AppAdminRoutes from './app/routes/app.admin.routes';
import AppResponsavelLeadRoutes from './app/routes/app.responsavellead.routes';
import AuthRoutes from './app/routes/auth.routes';

import 'react-notification-alert/dist/animate.css';
import 'react-pro-sidebar/dist/css/styles.css';
import 'react-datepicker/dist/react-datepicker.css';
import './app/assets/vendor/fontawesome-free-5.15.2-web/css/all.min.css';
import './app/assets/css/bootstrap.css';
import './app/assets/css/custom.css';

const loading = (
  <div
    className="bg-primary opacity-7 d-flex w-100 vh-100 align-items-center justify-content-center position-fixed left-0 top-0"
    style={{ zIndex: 999999 }}
  >
    <Spinner type="grow" />
  </div>
);

const App = () => {
  const { isAuthenticatedAdmin, isAuthenticatedResponsavelLead } = useAuth();

  console.log('isAuthenticatedAdmin', isAuthenticatedAdmin);
  console.log('isAuthenticatedResponsavelLead', isAuthenticatedResponsavelLead);
  return (
    <Router>
      <Suspense fallback={loading}>
        {isAuthenticatedAdmin ? (
          <ManagerProvider>
            <AppAdminRoutes />
          </ManagerProvider>
        ) : isAuthenticatedResponsavelLead ? (
          <ManagerProvider>
            <AppResponsavelLeadRoutes />
          </ManagerProvider>
        ) : (
          <AuthRoutes />
        )}
      </Suspense>
    </Router>
  );
};

export default App;
