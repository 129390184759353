import React from 'react';

const Dashboard = React.lazy(() => import('../pages/Dashboard'));
const Admins = React.lazy(() => import('../pages/Admins'));
const Consultores = React.lazy(() => import('../pages/Consultores'));
const ResponsavelLead = React.lazy(() => import('../pages/ResponsavelLead'));
const Clientes = React.lazy(() => import('../pages/Clientes'));
const Sistemas = React.lazy(() => import('../pages/Sistemas'));
const ContratoTipos = React.lazy(() => import('../pages/ContratoTipos'));
const Atividades = React.lazy(() => import('../pages/Atividades'));
const FormaPagamentos = React.lazy(() => import('../pages/FormaPagamentos'));
const Concorrentes = React.lazy(() => import('../pages/Concorrentes'));
const CargaDadosTipos = React.lazy(() => import('../pages/CargaDadosTipos'));
const Notificacoes = React.lazy(() => import('../pages/Notificacoes'));

const routes = [
  {
    path: '/',
    Component: Dashboard,
  },
  {
    path: 'admin/',
    name: 'Admins',
    Component: Admins,
  },
  {
    path: '/admin/consultores',
    Component: Consultores,
  },
  {
    path: '/admin/responsavel-lead',
    Component: ResponsavelLead,
  },
  {
    path: '/admin/clientes',
    Component: Clientes,
  },
  {
    path: 'admin/sistemas',
    Component: Sistemas,
  },
  {
    path: 'admin/tipos-contratos',
    Component: ContratoTipos,
  },
  {
    path: 'admin/atividades',
    Component: Atividades,
  },
  {
    path: 'admin/forma-pagamentos',
    Component: FormaPagamentos,
  },
  {
    path: 'admin/concorrentes',
    Component: Concorrentes,
  },
  {
    path: 'admin/tipos-carga-dados',
    Component: CargaDadosTipos,
  },
  {
    path: 'admin/notificacoes',
    Component: Notificacoes,
  },
];

export default routes;
