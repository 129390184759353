import React, {
  createContext,
  useState,
  useCallback,
  useMemo,
  useContext,
  useRef,
} from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';

import Notification from '../components/Notification';
import api from '../services/api';

export const ManagerContext = createContext();

export const ManagerProvider = ({ children }) => {
  const [alert, setAlert] = useState(null);
  const [datas, setDatas] = useState([]);
  const [loading, setLoading] = useState(false);
  const notificationRef = useRef();

  const handlerError = useCallback((error, title) => {
    if (typeof error === 'string') {
      notificationRef.current.notify({
        title,
        message: error,
        color: 'danger',
      });
      return;
    }

    if (error && error.response) {
      const { message } = error.response.data;
      if (message) {
        notificationRef.current.notify({
          title,
          message,
          color: 'danger',
        });
        return;
      }
    }

    notificationRef.current.notify({
      message: title,
      color: 'danger',
    });
  }, []);

  const load = useCallback(
    (url, onSuccess, onError) => {
      if (url) {
        (async () => {
          try {
            setDatas([]);
            setLoading(true);
            const res = await api.get(url);
            setDatas(res.data);
            /* notificationRef.current.notify({
              message: 'Carregado com sucesso!!!',
            }); */
            if (onSuccess) {
              onSuccess();
            }
            setLoading(false);
          } catch (error) {
            if (onError) {
              onError(error);
            }
            setLoading(false);
            handlerError(error, 'Falha ao carregar os dados');
          }
        })();
      }
    },
    [handlerError],
  );

  const store = useCallback(
    (url, data, onSuccess, onError) => {
      if (url && data) {
        (async () => {
          try {
            setLoading(true);
            const res = await api.post(url, data);
            setDatas((d) => [res.data, ...d]);
            notificationRef.current.notify({
              message: 'Cadastrado com sucesso!!!',
            });
            if (onSuccess) {
              onSuccess(res.data);
            }
            setLoading(false);
          } catch (error) {
            if (onError) {
              onError(error);
            }
            setLoading(false);
            handlerError(error, 'Falha ao Cadastrar');
          }
        })();
      }
    },
    [handlerError],
  );

  const update = useCallback(
    (id, url, data, onSuccess, onError) => {
      if (id && url && data) {
        (async () => {
          try {
            console.log(data);
            setLoading(true);
            const res = await api.put(`${url}/${id}`, data);
            setDatas((d) => [
              res.data,
              ...d.filter((value) => (value?.id || value?._id) !== id),
            ]);
            notificationRef.current.notify({
              message: 'Alterado com sucesso!!!',
            });
            if (onSuccess) {
              onSuccess(res.data);
            }
            setLoading(false);
          } catch (error) {
            console.log(error);
            if (onError) {
              onError(error);
            }
            setLoading(false);
            handlerError(error, 'Falha ao alterar');
          }
        })();
      }
    },
    [handlerError],
  );

  const destroy = useCallback(
    (id, url, onSuccess, onError) => {
      if (id && url) {
        (async () => {
          try {
            setAlert(
              <SweetAlert
                danger
                title="Confirm"
                onCancel={() => setAlert(null)}
                showCancel
                showCloseButton
                confirmBtnBsStyle="danger"
                confirmBtnText="Excluir"
                cancelBtnBsStyle="link"
                cancelBtnText="Cancelar"
                reverseButtons
                onConfirm={async () => {
                  try {
                    setLoading(true);
                    await api.delete(`${url}/${id}`);
                    setDatas((d) => d.filter((value) => (value.id || value._id) !== id));
                    setAlert(null);
                    notificationRef.current.notify({
                      message: 'Excluido com sucesso',
                    });
                    if (onSuccess) {
                      onSuccess();
                    }
                    setLoading(false);
                  } catch (error) {
                    if (onError) {
                      onError(error);
                    }
                    setLoading(false);
                    handlerError(error, 'Falha ao excluir');
                  }
                }}
              >
                <strong>Tem certeza que deseja excluir?</strong>
              </SweetAlert>,
            );
          } catch (error) {
            if (onError) {
              onError(error);
            }
            setLoading(false);
            handlerError(error, 'Falha ao excluir');
          }
        })();
      }
    },
    [handlerError],
  );

  const value = useMemo(
    () => ({
      loading,
      datas,
      setDatas,
      load,
      store,
      update,
      destroy,
    }),
    [loading, datas, setDatas, load, store, update, destroy],
  );

  return (
    <ManagerContext.Provider value={value}>
      {alert}
      <Notification ref={notificationRef} />
      {children}
    </ManagerContext.Provider>
  );
};

export const useManager = () => {
  const context = useContext(ManagerContext);

  if (!context) {
    throw new Error('useManager must be used within a ManagerProvider');
  }

  return context;
};

export default ManagerProvider;
