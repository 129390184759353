import React from 'react';

const LoginAdmin = React.lazy(() => import('../pages/LoginAdmin'));
const LoginResponsavelLead = React.lazy(() => import('../pages/LoginResponsavelLead'));

export default [
  { path: '/', Component: LoginAdmin },
  { path: '/login', Component: LoginAdmin },
  { path: '/login-responsavel-lead', Component: LoginResponsavelLead },
  { path: '*', Component: LoginAdmin },
];
